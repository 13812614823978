<template>
  <div class="w-full">
    <div class="flex space-x-6">
      <div class="flex w-3/5 flex-col justify-between space-y-6 text-sm">
        <div class="inline-flex">
          <div class="w-1/4">
            <base-label class="text-sm"> Title </base-label>
          </div>
          <div class="flex-1">
            <base-input v-model="eQuestion.title" />
          </div>
        </div>

        <div class="inline-flex">
          <div class="w-1/4">
            <base-label class="text-sm"> Category </base-label>
          </div>
          <div class="flex-1">
            <v-select
              id="category"
              v-model="eQuestion.category"
              class="w-full"
              label="name"
              :clearable="false"
              :options="categoryList"
              @input="
                (eQuestion.category_id = $event.id),
                  (eQuestion.category = $event)
              "
            />
          </div>
        </div>
      </div>

      <div class="flex flex-1 flex-col space-y-10">
        <div class="inline-flex items-center">
          <div class="w-1/2">
            <base-label class="text-sm"> Published </base-label>
          </div>
          <div class="flex-1">
            <div class="form-switch max-h-full">
              <input
                :id="'publish' + eQuestion.id"
                v-model="eQuestion.status"
                type="checkbox"
                name="publish"
                class="form-switch-checkbox"
              />
              <label
                class="form-switch-label display-none"
                :for="'publish' + eQuestion.id"
              />
            </div>
          </div>
        </div>

        <div class="inline-flex items-center">
          <div class="w-1/2">
            <base-label class="text-sm"> Answered </base-label>
          </div>
          <div class="flex-1">
            <div class="form-switch max-h-full">
              <input
                :id="'answer' + eQuestion.id"
                v-model="eQuestion.answered"
                type="checkbox"
                name="answer"
                class="form-switch-checkbox"
              />
              <label
                class="form-switch-label display-none"
                :for="'answer' + eQuestion.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      eQuestion: {
        title: this.$props.question.title,
        published: this.$props.question.published,
        category: this.$props.question.category,
        answered: this.$props.question.answered,
        status: this.$props.question.status,
      },

      statuses: [
        {
          id: 1,
          name: 'Published',
        },
        {
          id: 0,
          name: 'Unpublished',
        },
      ],
    };
  },

  computed: {
    ...mapState('question', ['categories']),
    categoryList() {
      return this.categories.map((cat, index) => {
        return {
          id: index,
          name: cat,
        };
      });
    },
  },

  watch: {
    eQuestion: {
      deep: true,
      handler() {
        this.$emit('changed', this.eQuestion);
      },
    },
  },
};
</script>
