var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full" }, [
    _c("div", { staticClass: "flex space-x-6" }, [
      _c(
        "div",
        {
          staticClass: "flex w-3/5 flex-col justify-between space-y-6 text-sm",
        },
        [
          _c("div", { staticClass: "inline-flex" }, [
            _c(
              "div",
              { staticClass: "w-1/4" },
              [
                _c("base-label", { staticClass: "text-sm" }, [
                  _vm._v(" Title "),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex-1" },
              [
                _c("base-input", {
                  model: {
                    value: _vm.eQuestion.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.eQuestion, "title", $$v)
                    },
                    expression: "eQuestion.title",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "inline-flex" }, [
            _c(
              "div",
              { staticClass: "w-1/4" },
              [
                _c("base-label", { staticClass: "text-sm" }, [
                  _vm._v(" Category "),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex-1" },
              [
                _c("v-select", {
                  staticClass: "w-full",
                  attrs: {
                    id: "category",
                    label: "name",
                    clearable: false,
                    options: _vm.categoryList,
                  },
                  on: {
                    input: function ($event) {
                      ;(_vm.eQuestion.category_id = $event.id),
                        (_vm.eQuestion.category = $event)
                    },
                  },
                  model: {
                    value: _vm.eQuestion.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.eQuestion, "category", $$v)
                    },
                    expression: "eQuestion.category",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "flex flex-1 flex-col space-y-10" }, [
        _c("div", { staticClass: "inline-flex items-center" }, [
          _c(
            "div",
            { staticClass: "w-1/2" },
            [
              _c("base-label", { staticClass: "text-sm" }, [
                _vm._v(" Published "),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "flex-1" }, [
            _c("div", { staticClass: "form-switch max-h-full" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.eQuestion.status,
                    expression: "eQuestion.status",
                  },
                ],
                staticClass: "form-switch-checkbox",
                attrs: {
                  id: "publish" + _vm.eQuestion.id,
                  type: "checkbox",
                  name: "publish",
                },
                domProps: {
                  checked: Array.isArray(_vm.eQuestion.status)
                    ? _vm._i(_vm.eQuestion.status, null) > -1
                    : _vm.eQuestion.status,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.eQuestion.status,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.eQuestion, "status", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.eQuestion,
                            "status",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.eQuestion, "status", $$c)
                    }
                  },
                },
              }),
              _c("label", {
                staticClass: "form-switch-label display-none",
                attrs: { for: "publish" + _vm.eQuestion.id },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "inline-flex items-center" }, [
          _c(
            "div",
            { staticClass: "w-1/2" },
            [
              _c("base-label", { staticClass: "text-sm" }, [
                _vm._v(" Answered "),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "flex-1" }, [
            _c("div", { staticClass: "form-switch max-h-full" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.eQuestion.answered,
                    expression: "eQuestion.answered",
                  },
                ],
                staticClass: "form-switch-checkbox",
                attrs: {
                  id: "answer" + _vm.eQuestion.id,
                  type: "checkbox",
                  name: "answer",
                },
                domProps: {
                  checked: Array.isArray(_vm.eQuestion.answered)
                    ? _vm._i(_vm.eQuestion.answered, null) > -1
                    : _vm.eQuestion.answered,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.eQuestion.answered,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.eQuestion, "answered", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.eQuestion,
                            "answered",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.eQuestion, "answered", $$c)
                    }
                  },
                },
              }),
              _c("label", {
                staticClass: "form-switch-label display-none",
                attrs: { for: "answer" + _vm.eQuestion.id },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }