var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex h-full flex-col overflow-hidden" }, [
    _c(
      "div",
      { staticClass: "border-b bg-beigeLight bg-opacity-50 py-2 px-5" },
      [
        _c("p", { staticClass: "font-display text-lg tracking-wide" }, [
          _vm.view === "information"
            ? _c("span", [_vm._v("Information")])
            : _vm._e(),
          _vm.view === "history"
            ? _c("span", [
                _vm._v(_vm._s(_vm.currentAnimal.name) + "s previous records"),
              ])
            : _vm._e(),
        ]),
      ]
    ),
    _c("div", { staticClass: "flex h-full" }, [
      _c(
        "div",
        { staticClass: "flex h-full flex-1 flex-col overflow-hidden" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.view === "information",
                  expression: "view === 'information'",
                },
              ],
              staticClass: "flex h-full overflow-auto",
            },
            [
              _c(
                "div",
                { staticClass: "flex h-full flex-1 flex-col overflow-auto" },
                [
                  _c("div", { staticClass: "flex-1 px-5 py-5" }, [
                    _c("div", { staticClass: "flex flex-col space-y-6" }, [
                      _c(
                        "div",
                        [
                          _c("title-row", { staticClass: "mb-4" }, [
                            _vm._v(" Question "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "flex flex-col space-y-1" },
                            [
                              _c("p", [
                                _vm._v("ID: " + _vm._s(_vm.eQuestion.id)),
                              ]),
                              _c("p", [
                                _vm._v(
                                  " Sent in: " +
                                    _vm._s(
                                      _vm.$formatDate(_vm.eQuestion.created_at)
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.$formatTime(_vm.eQuestion.created_at)
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "Original title: " +
                                    _vm._s(_vm.eQuestion.original_title)
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "URL-slug: " + _vm._s(_vm.eQuestion.slug)
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("title-row", { staticClass: "mb-4" }, [
                            _vm._v(" Customer "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "flex flex-col space-y-1" },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.eQuestion.customer.display_name)
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.eQuestion.customer
                                      .mobile_phone_formatted
                                  )
                                ),
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.eQuestion.customer.email)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("title-row", { staticClass: "mb-4" }, [
                            _vm._v("Animal insurance"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "flex flex-col space-y-1" },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.eQuestion.animal.insurance_type.name
                                  )
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "Free meetings: " +
                                    _vm._s(_vm.eQuestion.animal.free)
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("title-row", { staticClass: "mb-4" }, [
                            _vm._v(" Animal "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "flex flex-col space-y-1" },
                            [
                              _c("p", [
                                _vm._v(
                                  " " + _vm._s(_vm.eQuestion.animal.name) + " "
                                ),
                                _vm.eQuestion.animal.animal_type &&
                                _vm.eQuestion.animal.animal_type.name
                                  ? _c("span", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(
                                            _vm.eQuestion.animal.animal_type
                                              .name
                                          ) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm.eQuestion.animal.race
                                ? _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.eQuestion.animal.race) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$getAge(
                                        _vm.eQuestion.animal.birthdate
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(" " + _vm._s(_vm.animalGender) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "text-button",
                            {
                              staticClass: "mt-4",
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.view = "history"
                                },
                              },
                            },
                            [_vm._v(" View previous records ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.view === "history",
                  expression: "view === 'history'",
                },
              ],
              staticClass: "h-full overflow-auto",
            },
            [
              _c("div", { staticClass: "flex flex-col" }, [
                _c(
                  "div",
                  { staticClass: "px-5 py-5" },
                  [
                    _c("animal-previous-records", {
                      ref: "currentAnimalRecords",
                      staticClass: "overflow-auto",
                      attrs: {
                        animal: _vm.currentAnimal,
                        "open-history-on-mount": true,
                        "show-tab-action-button": false,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex min-h-full flex-col bg-beigeLight bg-opacity-50" },
        [
          _c(
            "nav-button",
            {
              staticClass: "py-3",
              attrs: { color: "dark" },
              on: {
                click: function ($event) {
                  _vm.view = "information"
                },
              },
            },
            [_c("fv-icon", { attrs: { icon: "information" } })],
            1
          ),
          _c(
            "nav-button",
            {
              staticClass: "py-3",
              attrs: { color: "dark" },
              on: {
                click: function ($event) {
                  _vm.view = "history"
                },
              },
            },
            [_c("fv-icon", { attrs: { icon: "consultations" } })],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }