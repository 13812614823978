var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "default-layout",
    { staticClass: "flex" },
    [
      _c("spinner-overlay", {
        staticClass: "h-full",
        attrs: { color: "light", loading: !_vm.drawEditors },
      }),
      _vm.drawEditors
        ? _c(
            "div",
            { staticClass: "z-10 mx-auto w-full", attrs: { id: "editArea" } },
            [
              _c("div", { staticClass: "flex h-full space-x-2" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-3/5 flex-shrink-0 overflow-auto rounded border bg-white shadow",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex bg-beigeLight bg-opacity-50 py-2 px-4",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex w-full items-center justify-between",
                          },
                          [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "font-display text-lg tracking-wide",
                              },
                              [_vm._v(" " + _vm._s(_vm.question.title) + " ")]
                            ),
                            _c(
                              "p",
                              { staticClass: "font-display tracking-wide" },
                              [_vm._v("# " + _vm._s(_vm.question.id))]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex w-full flex-col space-y-6 px-4 pt-6",
                      },
                      [
                        _c("QuestionBaseInfoSection", {
                          attrs: { question: _vm.question },
                          on: { changed: _vm.setQuestionData },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "mb-4 flex flex-1 flex-col space-y-6 pt-4",
                            attrs: { id: "editorArea" },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("ckeditor", {
                                  attrs: {
                                    editor: _vm.editor,
                                    config: _vm.editorConfig,
                                  },
                                  model: {
                                    value: _vm.eQuestion.question,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.eQuestion, "question", $$v)
                                    },
                                    expression: "eQuestion.question",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  { staticClass: "mb-2" },
                                  [
                                    _c("base-label", [
                                      _vm._v(" Private answer "),
                                    ]),
                                    _c("p", [
                                      _c("small", [
                                        _vm._v(
                                          "Only visible to the animal owner"
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("ckeditor", {
                                  attrs: {
                                    id: "privateAnswer",
                                    editor: _vm.editor,
                                    config: _vm.editorConfig,
                                  },
                                  model: {
                                    value: _vm.eQuestion.answer_private,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.eQuestion,
                                        "answer_private",
                                        $$v
                                      )
                                    },
                                    expression: "eQuestion.answer_private",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  { staticClass: "mb-2" },
                                  [
                                    _c("base-label", [
                                      _vm._v(" Public answer "),
                                    ]),
                                    _c("p", [
                                      _c("small", [
                                        _vm._v("Visible to anyone"),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("ckeditor", {
                                  attrs: {
                                    id: "publicAnswer",
                                    editor: _vm.editor,
                                    config: _vm.editorConfig,
                                  },
                                  model: {
                                    value: _vm.eQuestion.answer,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.eQuestion, "answer", $$v)
                                    },
                                    expression: "eQuestion.answer",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-col space-y-4 py-8 px-4",
                        class: { "opacity-50": _vm.loading },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-1" },
                          [
                            _c(
                              "base-button",
                              {
                                staticClass: "w-full",
                                attrs: {
                                  loading: _vm.loading,
                                  color: "primary",
                                  disabled: _vm.loading,
                                },
                                on: { click: _vm.submit },
                              },
                              [_vm._v(" Save and exit ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex w-full justify-between space-x-4",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-1" },
                              [
                                _c(
                                  "base-button",
                                  {
                                    staticClass: "w-full",
                                    attrs: {
                                      color: "cancel",
                                      to: { name: "questions" },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex-1" },
                              [
                                _c(
                                  "base-button",
                                  {
                                    staticClass: "w-full",
                                    attrs: { color: "light" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showPreview = true
                                      },
                                    },
                                  },
                                  [_vm._v(" Preview ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "h-full w-1/2 flex-shrink-0 rounded border bg-white shadow-sm",
                  },
                  [
                    _c("QuestionInformationSection", {
                      attrs: { question: _vm.question },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "base-modal",
        {
          attrs: { show: _vm.showPreview },
          on: {
            close: function ($event) {
              _vm.showPreview = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex p-4" },
            [_c("question-preview", { attrs: { question: _vm.eQuestion } })],
            1
          ),
          _c(
            "div",
            {
              staticClass: "inline-flex space-x-2 text-center",
              class: { "opacity-50": _vm.loading },
              attrs: { slot: "buttons" },
              slot: "buttons",
            },
            [
              _c(
                "base-button",
                {
                  attrs: { color: "cancel" },
                  on: {
                    click: function ($event) {
                      _vm.showPreview = false
                    },
                  },
                },
                [_vm._v(" Continue editing ")]
              ),
              _c(
                "base-button",
                {
                  attrs: { color: "primary", disabled: _vm.loading },
                  on: { click: _vm.submit },
                },
                [
                  _vm._v(" Submit "),
                  _vm.loading ? _c("base-spinner") : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }