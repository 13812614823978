<template>
  <default-layout class="flex">
    <spinner-overlay class="h-full" color="light" :loading="!drawEditors" />
    <div v-if="drawEditors" id="editArea" class="z-10 mx-auto w-full">
      <div class="flex h-full space-x-2">
        <div
          class="w-3/5 flex-shrink-0 overflow-auto rounded border bg-white shadow"
        >
          <div class="flex bg-beigeLight bg-opacity-50 py-2 px-4">
            <div class="flex w-full items-center justify-between">
              <h1 class="font-display text-lg tracking-wide">
                {{ question.title }}
              </h1>
              <p class="font-display tracking-wide"># {{ question.id }}</p>
            </div>
          </div>

          <div class="flex w-full flex-col space-y-6 px-4 pt-6">
            <QuestionBaseInfoSection
              :question="question"
              @changed="setQuestionData"
            />
            <div
              id="editorArea"
              class="mb-4 flex flex-1 flex-col space-y-6 pt-4"
            >
              <div>
                <ckeditor
                  v-model="eQuestion.question"
                  :editor="editor"
                  :config="editorConfig"
                />
              </div>

              <div>
                <div class="mb-2">
                  <base-label> Private answer </base-label>
                  <p><small>Only visible to the animal owner</small></p>
                </div>
                <ckeditor
                  id="privateAnswer"
                  v-model="eQuestion.answer_private"
                  :editor="editor"
                  :config="editorConfig"
                />
              </div>

              <div>
                <div class="mb-2">
                  <base-label> Public answer </base-label>
                  <p><small>Visible to anyone</small></p>
                </div>
                <ckeditor
                  id="publicAnswer"
                  v-model="eQuestion.answer"
                  :editor="editor"
                  :config="editorConfig"
                />
              </div>
            </div>
          </div>

          <div
            class="flex flex-col space-y-4 py-8 px-4"
            :class="{ 'opacity-50': loading }"
          >
            <div class="flex-1">
              <base-button
                :loading="loading"
                color="primary"
                class="w-full"
                :disabled="loading"
                @click="submit"
              >
                Save and exit
              </base-button>
            </div>

            <div class="flex w-full justify-between space-x-4">
              <div class="flex-1">
                <base-button
                  color="cancel"
                  class="w-full"
                  :to="{ name: 'questions' }"
                >
                  Cancel
                </base-button>
              </div>

              <div class="flex-1">
                <base-button
                  color="light"
                  class="w-full"
                  @click="showPreview = true"
                >
                  Preview
                </base-button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="h-full w-1/2 flex-shrink-0 rounded border bg-white shadow-sm"
        >
          <QuestionInformationSection :question="question" />
        </div>
      </div>
    </div>

    <base-modal :show="showPreview" @close="showPreview = false">
      <div class="flex p-4">
        <question-preview :question="eQuestion" />
      </div>
      <div
        slot="buttons"
        class="inline-flex space-x-2 text-center"
        :class="{ 'opacity-50': loading }"
      >
        <base-button color="cancel" @click="showPreview = false">
          Continue editing
        </base-button>
        <base-button color="primary" :disabled="loading" @click="submit">
          Submit <base-spinner v-if="loading" />
        </base-button>
      </div>
    </base-modal>
  </default-layout>
</template>

<script>
import _ from 'lodash';
import '@/plugins/ckeditor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mapState, mapActions } from 'vuex';
import QuestionPreview from '@/components/questions/QuestionPreview.vue';
import QuestionBaseInfoSection from '@/components/questions/QuestionBaseInfoSection.vue';
import QuestionInformationSection from '@/components/questions/QuestionInformationSection.vue';

export default {
  components: {
    QuestionPreview,
    QuestionBaseInfoSection,
    QuestionInformationSection,
  },
  data() {
    return {
      drawEditors: false,

      loading: false,
      eQuestion: _.cloneDeep(this.$store.state.question.question),
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'bulletedList',
          'numberedList',
          'blockQuote',
          'link',
        ],
      },
      questionConfig: {
        toolbar: ['bulletedList', 'numberedList', 'blockQuote'],
      },
      noToolbar: {
        toolbar: [],
      },
      showPreview: false,
    };
  },
  computed: {
    ...mapState('question', ['question']),
  },
  mounted() {
    this.fetchQuestion(this.$route.params.id).then(res => {
      if (res.data.question) {
        this.eQuestion = { ...res.data.question };
        this.drawEditors = true;
        this.setAnimal(this.eQuestion.animal);
      }
    });
    this.fetchQuestions();
  },
  methods: {
    ...mapActions('question', [
      'fetchQuestion',
      'fetchQuestions',
      'updateQuestion',
    ]),
    ...mapActions('animal', ['setAnimal']),
    async submit() {
      this.loading = true;
      try {
        await this.updateQuestion(this.eQuestion);
        this.$router.push({ name: 'questions' });
        this.$notify({
          group: 'primary',
          text: 'Saved',
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    setQuestionData(mergeData) {
      this.eQuestion = { ...this.eQuestion, ...mergeData };
    },
  },
};
</script>

<style scoped lang="postcss">
>>> .ck-editor__editable {
  min-height: 180px;
}
>>> .ck-editor a {
  color: rgba(30, 64, 175, 1);
  text-decoration: underline;
}
>>> .ck-editor__editable {
  width: 100%;
  border-radius: 0 0 0.25rem 0.25rem !important;
  padding-left: 1rem;
  color: #1a202c;
  font-size: 15px;
}
>>> .ck-editor__editable h2 {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 1em;
  line-height: 1.3333333;
}
>>> .ck-editor__editable h3 {
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 0.6em;
  line-height: 1.6;
}
>>> .ck-editor__editable h4 {
  font-weight: 600;
  margin-bottom: 0.5em;
  line-height: 1.5;
}

>>> .ck-editor__editable li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 28px;
}
>>> .ck-editor__editable > .ck-placeholder::before {
  color: #a1aec1;
}
>>> .ck-toolbar {
  border-radius: 0.25rem 0.25rem 0 0 !important;
  padding-left: 0.75rem;
}
>>> .ck.ck-editor__main > div > p {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}
</style>
