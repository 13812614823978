<template>
  <div class="flex h-full flex-col overflow-hidden">
    <div class="border-b bg-beigeLight bg-opacity-50 py-2 px-5">
      <p class="font-display text-lg tracking-wide">
        <span v-if="view === 'information'">Information</span>
        <span v-if="view === 'history'"
          >{{ currentAnimal.name }}s previous records</span
        >
      </p>
    </div>

    <div class="flex h-full">
      <div class="flex h-full flex-1 flex-col overflow-hidden">
        <div v-show="view === 'information'" class="flex h-full overflow-auto">
          <div class="flex h-full flex-1 flex-col overflow-auto">
            <div class="flex-1 px-5 py-5">
              <div class="flex flex-col space-y-6">
                <div>
                  <title-row class="mb-4"> Question </title-row>
                  <div class="flex flex-col space-y-1">
                    <p>ID: {{ eQuestion.id }}</p>
                    <p>
                      Sent in: {{ $formatDate(eQuestion.created_at) }}
                      {{ $formatTime(eQuestion.created_at) }}
                    </p>
                    <p>Original title: {{ eQuestion.original_title }}</p>
                    <p>URL-slug: {{ eQuestion.slug }}</p>
                  </div>
                </div>
                <div>
                  <title-row class="mb-4"> Customer </title-row>
                  <div class="flex flex-col space-y-1">
                    <p>{{ eQuestion.customer.display_name }}</p>
                    <p>{{ eQuestion.customer.mobile_phone_formatted }}</p>
                    <p>{{ eQuestion.customer.email }}</p>
                  </div>
                </div>

                <div>
                  <title-row class="mb-4">Animal insurance</title-row>
                  <div class="flex flex-col space-y-1">
                    <p>{{ eQuestion.animal.insurance_type.name }}</p>
                    <p>Free meetings: {{ eQuestion.animal.free }}</p>
                  </div>
                </div>

                <div>
                  <title-row class="mb-4"> Animal </title-row>
                  <div class="flex flex-col space-y-1">
                    <p>
                      {{ eQuestion.animal.name }}
                      <span
                        v-if="
                          eQuestion.animal.animal_type &&
                          eQuestion.animal.animal_type.name
                        "
                        >({{ eQuestion.animal.animal_type.name }})</span
                      >
                    </p>
                    <p v-if="eQuestion.animal.race">
                      {{ eQuestion.animal.race }}
                    </p>
                    <p>
                      {{ $getAge(eQuestion.animal.birthdate) }}
                    </p>
                    <p>
                      {{ animalGender }}
                    </p>
                  </div>
                  <text-button
                    class="mt-4"
                    color="primary"
                    @click="view = 'history'"
                  >
                    View previous records
                  </text-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="view === 'history'" class="h-full overflow-auto">
          <div class="flex flex-col">
            <div class="px-5 py-5">
              <animal-previous-records
                ref="currentAnimalRecords"
                class="overflow-auto"
                :animal="currentAnimal"
                :open-history-on-mount="true"
                :show-tab-action-button="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex min-h-full flex-col bg-beigeLight bg-opacity-50">
        <nav-button color="dark" class="py-3" @click="view = 'information'">
          <fv-icon icon="information" />
        </nav-button>
        <nav-button color="dark" class="py-3" @click="view = 'history'">
          <fv-icon icon="consultations" />
        </nav-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AnimalPreviousRecords from '@/components/animal/previous-records/AnimalPreviousRecords.vue';

export default {
  components: {
    AnimalPreviousRecords,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      eQuestion: _.cloneDeep(this.$props.question),
      view: 'information',
    };
  },

  computed: {
    ...mapGetters('animal', { animalGender: 'getGenderName' }),
    currentAnimal() {
      return this.eQuestion.animal;
    },
  },
};
</script>
